import styled from "styled-components";
import { Link } from "react-router-dom";

export const FooterSection = styled("footer")`
  background: rgb(241, 241, 241);
  padding: 2rem 0;
`;

export const Title = styled("h4")`
  font-size: 22px;
  text-transform: capitalize;
  color: #323232;
  margin-top: 1rem;

  @media screen and (max-width: 414px) {
    padding: 1rem 0;
  }
`;

export const Extra = styled("section")`
  background: rgb(241, 241, 241);
  position: relative;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-bottom: 2rem;
  img { 
    -webkit-user-drag: none; 
    -khtml-user-drag: none; 
    -moz-user-drag: none; 
    -o-user-drag: none; 
    user-drag: none; 
  }
`;

export const CopyrightText = styled("span")`
  display: flex;
  align-items: center;
  color: #323232;
`;

export const Para = styled("div")`
  color: #323232;
  font-size: 14px;
  width: 70%;
`;

export const NavLink = styled(Link)<any>`
  font-size: 16px;
  color: #323232;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-transform: capitalize;
  line-height: 24px;
  display: block;
  margin-bottom: 0.625rem;
  transition: all 0.3s ease-in-out;
  max-width: max-content;

  &:hover {
    color: #EB3F00;
    text-underline-position: under;
  }
`;

export const LanguageSwitch = styled("div")<any>`
  cursor: pointer;
  transition: all 0.1s ease-in-out;

  &:hover,
  &:active,
  &:focus {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
  }
`;

export const LanguageSwitchContainer = styled("div")`
  display: flex;
  justify-content: space-between;
  img { 
    -webkit-user-drag: none; 
    -khtml-user-drag: none; 
    -moz-user-drag: none; 
    -o-user-drag: none; 
    user-drag: none; 
  }
`;
