import { createGlobalStyle } from "styled-components";

export const Styles = createGlobalStyle`
    @font-face {
        font-family: "Noto Sans Regular";
        src: url("/fonts/NotoSans-Regular.ttf") format("truetype");
        font-style: normal;
    }

    @font-face {
        font-family: "Noto Sans Bold";
        src: url("/fonts/NotoSans-Bold.ttf") format("truetype");
        font-style: normal;
    }

    body,
    html,
    a {
        font-family: 'Noto Sans Regular', sans-serif;
    }

    body {
        margin:0;
        padding:0;
        border: 0;
        outline: 0;
        background: #fff;
        overflow-x: hidden;
    }

    a:hover {
        color: #EB3F00;
    }

    input,
    textarea {
        border-radius: 4px;
        border: 0;
        background: rgb(241, 241, 241);
        transition: all 0.3s ease-in-out;  
        outline: none;
        width: 100%;  
        padding: 1rem 1.25rem;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: 'Noto Sans Bold', serif;
        color: #323232;
        font-size: 56px;
        line-height: 1.18;

        @media only screen and (max-width: 890px) {
          font-size: 47px;
        }
      
        @media only screen and (max-width: 414px) {
          font-size: 32px;
        }
    }

    p {
      color: #323232;
      font-size: 21px;        
      line-height: 1.41;
    }

    h1 {
      font-weight: 600;
    }

    a {
      text-decoration: none;
      outline: none;
      color: #000000;

      :hover {
        color: #EB3F00;
      }
    }
    
    *:focus {
      outline: none;
    }

    .about-block-image svg {
      text-align: center;
    }

    .ant-drawer-body {
      display: flex;
      flex-direction: column;
      text-align: left;
      padding-top: 1.5rem;
    }

    .ant-drawer-content-wrapper {
      width: 300px !important;
    }
`;
