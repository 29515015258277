import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import XHR from "i18next-xhr-backend";

import translationEn from "./locales/en/translation.json";
import translationRu from "./locales/ru/translation.json";
import translationDe from "./locales/de/translation.json";
import translationEs from "./locales/es/translation.json";
import translationJa from "./locales/ja/translation.json";
import translationFr from "./locales/fr/translation.json";

const supportedLngs = ["ru", "fr", "de", "es", "ja", "en"]

function browserLanguage(): string | undefined {
  var lang = navigator.language;
  for (const l in supportedLngs) {
    if (lang === l || lang.startsWith(l + "-")) {
      return l;
    }
  }
  return undefined;
}

i18n
  .use(XHR)
  .use(LanguageDetector)
  .init({
    debug: false,
    supportedLngs: supportedLngs,
    lng: browserLanguage(),
    fallbackLng: "en",

    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },

    resources: {
      en: {
        translations: translationEn,
      },
      ru: {
        translations: translationRu,
      },
      de: {
        translations: translationDe,
      },
      es: {
        translations: translationEs,
      },
      fr: {
        translations: translationFr,
      },
      ja: {
        translations: translationJa,
      },
    },
    ns: ["translations"],
    defaultNS: "translations",
  });

export default i18n;