import { useState } from "react";
import { useLocation } from "react-router-dom";
import { Row, Col, Drawer } from "antd";
import { withTranslation } from "react-i18next";
import Container from "../../common/Container";
import { SvgIcon } from "../../common/SvgIcon";

import Links from "../../content/Links.json";

import {
  HeaderSection,
  LogoContainer,
  Burger,
  NotHidden,
  LogoText,
  CustomNavLinkSmall,
  Label,
  Outline,
  Span,
} from "./styles";

const Header = ({ t }: any) => {
  const [visible, setVisibility] = useState(false);
  const {pathname} = useLocation()

  const showDrawer = () => {
    setVisibility(!visible);
  };

  const onClose = () => {
    setVisibility(!visible);
  };

  const MenuItem = () => {
    const scrollTo = (id: string) => {
      const element = document.getElementById(id) as HTMLDivElement;
      element.scrollIntoView({
        behavior: "smooth",
      });
      setVisibility(false);
    };
    return (
      <>
        <CustomNavLinkSmall onClick={() => window.open(Links.appstore, '_blank', 'noopener')}>
          <Span>{t("id_header_download")}</Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall onClick={() => scrollTo("features")}>
          <Span>{t("id_header_features")}</Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall onClick={() => scrollTo("contact")}>
          <Span>{t("id_header_contact")}</Span>
        </CustomNavLinkSmall>
      </>
    );
  };

  return (
    <HeaderSection>
      <Container>
        <Row justify="space-between">
          <LogoContainer to="/" aria-label="homepage">
            <SvgIcon src="logo.svg" width="48px" height="48px" />
            <LogoText>Goose Maps</LogoText>
          </LogoContainer>
          {pathname === "/" &&
            <>
              <NotHidden>
                <MenuItem />
              </NotHidden>
              <Burger onClick={showDrawer}>
                <Outline />
              </Burger>
            </>
          }
        </Row>
        {pathname === "/" &&
          <Drawer closable={false} visible={visible} onClose={onClose}>
            <Col style={{ marginBottom: "2rem" }}>
              <Label onClick={onClose}>
                <Col span={12}>
                </Col>
                <Col span={12}>
                  <Outline />
                </Col>
              </Label>
            </Col>
            <MenuItem />
          </Drawer>
        }
      </Container>
    </HeaderSection>
  );
};

export default withTranslation()(Header);
