const routes = [
  {
    path: ["/"],
    exact: true,
    component: "Home",
  },
  {
    path: ["/privacy_policy"],
    exact: true,
    component: "PrivacyPolicy",
  },
  {
    path: ["/terms_of_use"],
    exact: true,
    component: "TermsOfUse",
  },
  {
    path: ["/contact"],
    exact: true,
    component: "Contact",
  },
];

export default routes;
