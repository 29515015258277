import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import { LoaderContainer } from "./styles";

const SpinnerLoader = () => (
  <LoaderContainer>
    <Loader
      type="TailSpin"
      color="#323232"
      height={50}
      width={50}
      timeout={1000}
    />
  </LoaderContainer>
);

export default SpinnerLoader;